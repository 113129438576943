import { createSelector } from '@ngrx/store';
import { serviceEntityAdapter } from './service.state';
import { Service } from './service';
import { State } from 'src/app/root-store/root-state';

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = serviceEntityAdapter.getSelectors();

const serviceEntities = (state: State) =>  {
  return state.services?.services?.entities ?? [];
};

export const services = createSelector(serviceEntities, state => Object.values(state) as Array<Service>);
export const currentService = (state: any) => state.services.currentService;
