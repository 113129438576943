import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() name: string;
  @Input() toolTip: string;
  @Input() icon: string;
  @Input() position = 'right';
  @Input() highlight = false;
  @Input() isSVGIcon = false;
  @Output() showMenu = new EventEmitter();
  @Output() hideMenu = new EventEmitter();
  @ViewChild('filterPanel') panel: ElementRef;
  public panelVisible = false;
  public id;
  constructor(private eRef: ElementRef, private renderer: Renderer2) {
    this.id = '' + Math.round(Math.random() * 10000000);
  }

  ngOnInit(): void {
  }
  togglePanel(){
    this.panelVisible = !this.panelVisible;
    if (this.panelVisible) {
      this.showMenu.emit();
      this.positionPanel();
    } else {
      this.hideMenu.emit();
    }
  }
  private positionPanel() {

    const buttonLocation = this.eRef.nativeElement.getBoundingClientRect();
    console.log('positionPanel: ', this.position, buttonLocation);
    if ('left' === this.position) {
      const xOffSet = window.innerWidth - buttonLocation.x - buttonLocation.width;
      this.renderer.setStyle(this.panel.nativeElement, 'right', `${xOffSet}px`);
    } else {
      const xOffSet = buttonLocation.x;
      this.renderer.setStyle(this.panel.nativeElement, 'left', `${xOffSet}px`);
    }
  }

  close(){
    this.panelVisible = false;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.panelVisible) {
      return;
    }
    if (!this.eRef.nativeElement.contains(event.target)) {
      let hide = true;
      // console.log('event.path: ', event.path);
      event.path.forEach(el => {
        // CHECK IF CHILD COMPONENT
        if (el.id === this.id) {
          hide = false;
        }
        if (el.classList?.contains('cdk-overlay-container')){
          hide = false;
        }
      });
      if (hide){
        this.panelVisible = false;
      }
    }
  }


}
