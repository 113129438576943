import { Action, createReducer, on } from '@ngrx/store';
import { State, serviceEntityAdapter, servicesInitialState } from './service.state';
import * as ServicesActions from './service-versions.actions';

const setData = (state: State, { data }) => {
  return {
    ...state,
    services: serviceEntityAdapter.setAll(data, state.services)
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    services: serviceEntityAdapter.removeAll(state.services),
  };
};
const add = (state: State, { service }) => {
  return {
    ...state,
    services: serviceEntityAdapter.addOne(service, state.services),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    services: serviceEntityAdapter.updateOne({ id, changes }, state.services),
  };
};
const remove = (state: State, { service }) => {
  return {
    ...state,
    services: serviceEntityAdapter.removeOne(service.id, state.services),
  };
};

export const servicesReducers = createReducer(
  servicesInitialState,
  on(ServicesActions.loadServicesSuccess, setData),
  on(ServicesActions.deployServiceSuccess, add),
  on(ServicesActions.deleteServiceSuccess, remove),
  on(ServicesActions.updateServiceSuccess, update),
);

export function reducer(state: State | undefined, action: Action): any {
  return servicesReducers(state, action);
}
