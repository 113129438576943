<img class="logo" src="assets/images/vibeiq_logo.jpg" />
<div *ngIf="user">
  <div class="message">
    At this time, your profile <span class="email">{{ user?.email }}</span> does not have access to the resource you were trying to access.
  </div>
  <div class="message">
    For assistance, please talk with your brand contact or company administrator.
  </div>
  <div class="logout message" (click)="logout()">
    Log in as a different user
  </div>
</div>

