import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { WebSocketService } from '../../web-socket/web-socket.service';
import { UserSession } from '../user-session';
import { UserSessionsService } from '../user-sessions-service';
import * as UserSessionActions from './user-sessions.actions';

@Injectable()
export class UserSessionsEffects {
  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private userSessionService: UserSessionsService
  ) {}

  joinUserSession$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserSessionActions.UserSessionActionTypes.JOIN_USER_SESSION),
      tap((action: any) => this.webSocketService.joinSession(action.sessionId))
    ),
    { dispatch: false }
  );


  loadUserSessions$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserSessionActions.UserSessionActionTypes.LOAD_REMOTE_USER_SESSIONS),
      switchMap((action: any) => {
        return from(this.userSessionService.loadUserSessions(action.sessionId))
          .pipe(
            map(data => UserSessionActions.loadRemoteUsersSuccess({ data })),
            catchError(error => observableOf(UserSessionActions.loadRemoteUsersFailure({ error })))
          )
      })
    )
  );

}
