import { Component, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements AfterViewInit, OnDestroy {

  @Input() lgGray = false;
  @Input() placeholder = '';
  @Output() valueChange = new EventEmitter<string>();
  searchInput = new UntypedFormControl();
  private sub: Subscription;

  constructor() { }

  ngAfterViewInit(): void {
    this.sub = this.searchInput.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap((text: string) => {
          this.valueChange.emit(text);
        })
      ).subscribe();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public clear() {
    this.searchInput.setValue(null);
  }
}
