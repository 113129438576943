<div class="placeholder">
  <h4 *ngIf="title" class="text-black60 text-center px-4">{{title}}</h4>
  <div class="icon p-5" *ngIf="icon">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </div>
  <div class="footer text-center text-caption text-black60 px-5 max-w-md" *ngIf="footer">
    {{footer}}
  </div>
  <div class="action p-5" *ngIf="actionLabel">
    <button mat-raised-button color="primary" (click)="triggerEvent()">{{actionLabel | uppercase}}</button>
  </div>
</div>
