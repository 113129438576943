import { createAction, props } from '@ngrx/store';

export enum LoadingIndicatorActionTypes {
  SET_LOADING = '[Loading Indicator] Set Loading',
  SET_MESSAGE = '[Loading Indicator] Set Message',
}
export const setMessage = createAction(
  LoadingIndicatorActionTypes.SET_MESSAGE,
  props<{message: string}>()
);
export const setLoading = createAction(
  LoadingIndicatorActionTypes.SET_LOADING,
  props<{loading: boolean, message?: string}>()
);
