import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-ui-test',
  templateUrl: './ui-test.component.html',
  styleUrls: ['./ui-test.component.scss']
})
export class UiTestComponent implements OnInit {

  emailForm1 = new UntypedFormControl
  emailForm2 = new UntypedFormControl
  emailForm3 = new UntypedFormControl

  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  constructor() { }

  ngOnInit(): void {
    this.emailForm1 = new UntypedFormControl(null, [Validators.required, Validators.email]);
    this.emailForm2 = new UntypedFormControl(null, [Validators.required, Validators.email]);
    this.emailForm3 = new UntypedFormControl(null, [Validators.required, Validators.email]);
  }

  get emailInValidMessage() {
    if (this.emailForm3.hasError('required')) {
      return 'You must enter a value';
    }
    return this.emailForm3.hasError('email') ? 'Not a valid email' : '';
  }

}
