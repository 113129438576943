import { Component, OnInit, SimpleChanges, ChangeDetectorRef, OnDestroy, NgZone } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  template: '<div></div>',
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) { }
  ngOnInit() {
    if (this.authService.isSignedIn()) {
      const returnUrl = this.route?.snapshot?.queryParams.returnUrl || '/';
      document.location.href = returnUrl
    }
  }

}
