import { Action, createReducer, on } from '@ngrx/store';
import { State, orgsEntityAdapter, orgsInitialState } from './orgs.state';
import * as AppsActions from './orgs.actions';

const setData = (state: State, { data }) => {
  return {
    ...state,
    orgs: orgsEntityAdapter.setAll(data, state.orgs)
  };
};


export const orgsReducers = createReducer(
  orgsInitialState,
  on(AppsActions.loadOrgsSuccess, setData),
);

export function reducer(state: State | undefined, action: Action): any {
  return orgsReducers(state, action);
}
