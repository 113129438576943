import { Component } from '@angular/core';

@Component({
  selector: 'app-help-selector',
  template: `
  <button class="btn-36" mat-button (click)="helpCenter()">
    <mat-icon class="opacity-60"> help_outline </mat-icon>
  </button>
  `
})
export class HelpSelectorComponent {
  helpCenter() {
    window.open('https://intercom.help/vibesolutions/en/', '_blank');
  }
}
