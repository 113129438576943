import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


const DOMAIN_MAP = {
  'PRODUCTION': '.vibeiq.com',
  'DEVELOPMENT': '.dev.vibeiq.com',
  'STAGING': '-staging.vibeiq.com',
}
@Component({
  selector: 'app-app-switch',
  templateUrl: './app-switch.component.html',
  styleUrls: ['./app-switch.component.scss']
})
export class AppSwitchComponent implements OnInit {
  apps = [
    { icon: 'workspace_icon.svg', url: 'hub', name: 'Hub', hostParam: 'hubAppHost' },
    { icon: 'plan_icon.svg', url: 'plan', name: 'Plan', hostParam: 'planAppHost' },
    { icon: 'showcase_icon.svg', url: 'showcase-manager', name: 'Showcase', hostParam: 'showcaseViewerAppHost' },
    { icon: 'board_icon.svg', url: 'boards', name: 'Boards', hostParam: 'boardsAppHost' },
    { icon: 'showroom_icon.svg', url: 'showroom', name: 'Showroom', hostParam: 'showroomAppHost' },
    { icon: 'admin_icon.svg', url: 'admin', name: 'Admin', hostParam: 'adminAppHost' }
  ];

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void { }

  async gotoApp(url, name) {
    const context = await this.authService.authContext.pipe(take(1)).toPromise();
    const suffix = DOMAIN_MAP[environment.name] + '/org/';
    let link = `https://` + url + suffix + context.currentOrg.orgSlug + '/';
    if(environment.name === 'FEATURE_BRANCH') {
      link = environment[this.apps.find(x=>x.name === name)['hostParam']];
      if(!link) {
        console.error("Misconfigured app switcher in feature branch code");
      }
    }
    window.open(link, '_blank');
  }
}
