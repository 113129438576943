import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FilterPropertyDefinition } from '../../types/filters/filter-definition';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit, OnChanges {

  @Output() clearAllFiltersEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectedFiltersEvent: EventEmitter<any> = new EventEmitter();
  @Output() hideFilterEvent: EventEmitter<any> = new EventEmitter();

  @Input() filters: Array<FilterPropertyDefinition>;
  @Input() clearAll = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(`clearAll ${this.clearAll}`);
  }

  ngOnInit(): void {
    console.log(`~~~~~~~~~~~~~~~~~~ 2. input received is ${this.filters}`);
  }


  toggleFilterMenu() {
    // console.log('clicked toggleFilterMenu')
    this.hideFilterEvent.emit(true);
  }

  clearAllFilters() {
    this.clearAll = true;
    this.clearAllFiltersEvent.emit(true);
  }

  emitSelectedFilter(event){
    this.clearAll = false;
    this.selectedFiltersEvent.emit(this.filters);
  }
}
