import { Injectable } from "@angular/core";
import { Entities } from "@contrail/sdk";
import { Store } from "@ngrx/store";
import { RootStoreState } from "@rootstore";
import { Org } from "./org";
export interface FeatureFlagRegistry {
  [key: string]: boolean;
}

@Injectable({
  providedIn: "root",
})
export class OrgService {
  public async getOrgs(): Promise<Org[]> {
    return new Entities().get({ entityName: "org" });
  }

  public async createOrg(name): Promise<Org> {
    return new Entities().create({
      entityName: "org",
      object: { orgSlug: name, name },
    });
  }

  public async updateOrg(object: {
    id: string;
    isMonitored?: boolean;
    isArchived?: boolean;
  }): Promise<Org> {
    return new Entities().update({
      entityName: "org",
      id: object.id,
      object: {
        isMonitored: object.isMonitored,
        isArchived: object.isArchived,
      },
    });
  }
}
