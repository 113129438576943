import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ServicesActions } from '.';
import { RootStoreState } from '../../root-store';
import { ServiceVersionsService } from '../service-versions.service';

@Injectable()
export class ServicesEffects {
  constructor(
    private actions$: Actions,
    private serviceService: ServiceVersionsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar
  ) { }

  loadServices$ = createEffect(
    () => this.actions$.pipe(
      ofType(ServicesActions.ServicesActionTypes.LOAD_SERVICES),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.serviceService.getVersions())
          .pipe(
            map(data => ServicesActions.loadServicesSuccess({ data })),
            catchError(error => observableOf(ServicesActions.loadServicesFailure({ error })))
          );
      })
    )
  );

  deployService$ = createEffect(
    () => this.actions$.pipe(
      ofType(ServicesActions.ServicesActionTypes.DEPLOY_SERVICE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.serviceService.deployService(action.serviceName))
          .pipe(
            map(data => ServicesActions.loadServicesSuccess({ data })),
            catchError(error => observableOf(ServicesActions.loadServicesFailure({ error })))
          );
      })
    )
  );
}