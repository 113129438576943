import { Component } from '@angular/core';
import { AuthService, PlatformRole } from '@common/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {

  public authContext;
  public navItems = [];
  constructor(
    private authService: AuthService,
    public router: Router
  ) {
    this.authService.authContext.subscribe(authContext => {
      this.authContext = authContext;
      console.log(this.authContext)
      this.navItems = this.renderOptions(this.authContext?.user?.platformRoles ?? [])
    });
  }

  renderOptions(roles: Array<PlatformRole>) {
    const options = [];
    if (roles.includes(PlatformRole.PLATFORM_ADMIN) || roles.includes(PlatformRole.PLATFORM_OPS)) {
      options.push(
        {
          title: 'Operations', icon: 'construction', expanded: false, options: [
            { label: 'Services', route: '/services' },
            { label: 'Feature Branches', route: '/feature-branches' },
          ]
        }
      )
    }
    if (roles.includes(PlatformRole.PLATFORM_ADMIN) || roles.includes(PlatformRole.PLATFORM_MANAGER)) {
      options.push(
        {
          title: 'Orgs', icon: 'perm_identity', expanded: true, options: [
            { label: 'Orgs', route: '/orgs' },
            { label: 'Feature Flags', route: '/feature-flags', orgScoped: true },
          ]
        }
      )
    }
    if (roles.includes(PlatformRole.PLATFORM_ADMIN) || roles.includes(PlatformRole.PLATFORM_MANAGER)) {
      options.push(
        {
          title: 'Activity', icon: 'history', expanded: true, options: [
            { label: 'Document Access', route: '/document-access' },
            { label: 'User', route: '/user-activity' },
          ]
        }
      )
    }
    if (roles.includes(PlatformRole.PLATFORM_ADMIN)) {
      options.push(
        {
          title: 'Users', icon: 'perm_identity', expanded: true, options: [
            { label: 'Platform Users', route: '/platform-users' },
          ]
        }
      )
    }
    if (roles.includes(PlatformRole.PLATFORM_MANAGER) || roles.includes(PlatformRole.PLATFORM_ADMIN)) {
      options.push(
        {
          title: 'Entity Viewer', icon: 'perm_identity', expanded: true, options: [
            { label: 'Entity Viewer', route: '/entity-viewer' },
          ]
        }
      )
    }
    return options;
  }

  optionShow(idx) {
    this.navItems[idx].expanded = !this.navItems[idx].expanded;
  }

  go(route) {
    this.router.navigate([route]);
  }
}
