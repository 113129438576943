<div>
  <div class='filter-header'>
    <div>
      Filter
    </div>
    <div class='clearAll' (click)="clearAllFilters()">
      Clear All
    </div>
    <div class='closeFilterMenu' (click)='toggleFilterMenu()'>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class='filters-content'>
    <app-filter-entity *ngFor="let filter of filters" [filter]="filter" (filterValueChanged)="emitSelectedFilter($event)" [clearAll]="clearAll"></app-filter-entity>
  </div>
</div>
