import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { defaultStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AuthGuard } from './auth.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { AuthService } from './auth.service';
import { UserOrgResolver } from './user-org.resolver';
import { environment } from 'src/environments/environment';
import { NoOrgsRegisteredComponent } from './no-orgs-registered/no-orgs-registered.component';

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId: environment.userPoolId,
    userPoolClientId: environment.userPoolWebClientId
  }
};
Amplify.configure({
  Auth: authConfig
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

@NgModule({
  declarations: [LoginComponent, NoOrgsRegisteredComponent],
  imports: [
    CommonModule,
    AmplifyAuthenticatorModule,
  ],
  providers: [
    AuthGuard,
    AuthService,
    UserOrgResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    HttpClientModule,
  ],
  exports: [LoginComponent]
})
export class AuthModule { }
