import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/common/auth/auth.service';

@Component({
  selector: 'app-org-selector',
  templateUrl: './org-selector.component.html',
  styleUrls: ['./org-selector.component.scss']
})
export class OrgSelectorComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }
  selectedOrg: any;
  orgs: any[];

  async ngOnInit(): Promise<void> {
    this.orgs = (await this.authService.getCurrentUser()).orgs;
    this.selectedOrg = this.orgs[0];
    this.authService.authContext.subscribe(context => {
      this.selectedOrg = context?.currentOrg;
    });
  }

  selectOrg(org){
    this.selectedOrg = org;
    document.location.href = `/org/${org.orgSlug}/feature-flags/`;
  }
}
