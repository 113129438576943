import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mat-spinner',
  templateUrl: './mat-spinner.component.html',
  styleUrls: ['./mat-spinner.component.scss']
})
export class MatSpinnerComponent implements OnInit {

  constructor() {
    console.log(`Mat spinner constructor`);
  }
  @Input() message = 'Processing your request. Please wait...';
  @Input() value = 100;
  @Input() diameter = 60;
  @Input() mode = 'indeterminate';
  @Input() strokeWidth = 6;
  @Input() overlay = false;
  @Input() color = 'primary';
  @Input() loading = false;

  ngOnInit() {
  }
}
