import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { loadingIndicatorReducer } from './loading-indicator.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LoadingIndicatorEffects } from './loading-indicator.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('loadingIndicator', loadingIndicatorReducer),
    EffectsModule.forFeature([LoadingIndicatorEffects])
  ]
})
export class LoadingIndicatorStoreModule { }
