import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Org } from './org';


interface orgsState extends EntityState<Org> {}

export interface State {
  orgs: orgsState;
}
export const orgsEntityAdapter: EntityAdapter<Org> = createEntityAdapter<Org>({});

export const orgsInitialState = {
  orgs: orgsEntityAdapter.getInitialState({}),
};
