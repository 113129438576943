import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, PlatformRole } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformOpsAuthGuard  {
  constructor(private authService: AuthService) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.authService.getCurrentUser();
    return Boolean(user?.platformRoles?.includes(PlatformRole.PLATFORM_ADMIN)
      || user?.platformRoles?.includes(PlatformRole.PLATFORM_OPS)
    )
  }

}
