<div id="sidenav">
  <img class="logo" src="assets/images/vibeiq_logo.jpg" (click)="go('/')"/>

  <!-- <div class="flex flex-col ml-7 my-5">
    <app-search-bar class="w-60" [placeholder]="'Search admin console'" (valueChange)="searchNav($event)"></app-search-bar>
  </div> -->

  <div class="flex flex-col ml-7 narrow-scroll overflow-y-auto">
    <!-- <div class="nav-item text-title-2 w-60 pointer-events-none !mb-2">
      <span class="material-icons mr-6"> dashboard </span> Dashboard
    </div> -->
    <div class="flex flex-col mb-2 nav-wrap" [class.activated]="item.expanded" routerLinkActive="active"
         *ngFor="let item of navItems; index as idx">
      <div class="nav-item text-title-2" (click)="optionShow(idx);">
        <span class="material-icons -ml-1"> {{ item.expanded ? 'arrow_right' : 'arrow_drop_down'}} </span>
        <span class="material-icons mr-6"> {{item.icon}} </span>
        {{item.title}}
      </div>
      <ng-container *ngFor="let option of item.options">
        <div class="nav-item-option text-title-2" [class.!hidden]="item.expanded"
              (click)="$event.preventDefault(); $event.stopPropagation();"
              [routerLink]="option.orgScoped? '/org/' + authContext?.currentOrg?.orgSlug + '/' + option.route + '/': option.route" routerLinkActive="active">
          {{option.label}}
        </div>
      </ng-container>
    </div>
  </div>

</div>
