import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { orgsReducers } from './orgs.reducers';
import { OrgsEffects } from './orgs.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('orgs', orgsReducers),
    EffectsModule.forFeature([OrgsEffects])
  ]
})
export class OrgsStoreModule { }
