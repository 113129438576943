import { AuthContext } from '../auth.service';

export interface SharedLink {
  id?: string;
  contextType?: string;  // showcase
  contextId?: string;  // 12312-123123-123123-123123
  contextReference?: string;
  expires?: Date;
  deactivated?: boolean;
  accessLevel?: string;
}

export interface State {
  authContext: AuthContext;
  sharedLink: SharedLink;
}
export const initialState: State = {
  authContext: null,
  sharedLink: null,
};
