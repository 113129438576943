import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Service } from './store/service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceVersionsService {
  constructor() {
    // pass
  }

  public async getServiceVersionData(serviceName, serviceEndpoint): Promise<Service> {
    const versionData = await new Entities().get({entityName: serviceEndpoint, relation: 'version'});
    return new Service(serviceName, versionData.commit, versionData.date);

  }

  public async getVersions(): Promise<Service[]> {
    const versionData = await new Entities().get({entityName: 'service-manager', relation: 'service-versions'});
    return versionData.sort((a, b) => a.name > b.name).map(x => new Service(x.name, x.version, x.date));
  }

  public async deployService(serviceName): Promise<any> {
    const envToDeploy = environment.name === 'PRODUCTION'? 'prod': 'dev';
    return await new Entities().create({
      entityName: 'service-manager/deploy-service',
      object: {services: [serviceName], environment: envToDeploy}
    });
  }
}
