import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/common/auth/auth.service';

@Component({
  selector: 'app-select-org',
  templateUrl: './select-org.component.html',
  styleUrls: ['./select-org.component.scss']
})
export class SelectOrgComponent implements OnInit {

  public orgSlug;
  constructor(private authService: AuthService, private router: Router) {

  }
  ngOnInit(){
    this.authService.authContext.subscribe(context => {
      this.orgSlug = context?.currentOrg?.orgSlug;
      if(this.router.url !== `/org/${this.orgSlug}/feature-flags`) {
        setTimeout(() => {
          this.router.navigate([`/org/${this.orgSlug}/feature-flags`]);
        }, 2);
      }
    })
  }

}
