import { createAction, props } from '@ngrx/store';
import { AuthContext } from '../auth.service';
import { SharedLink } from './auth.state';

export enum AuthActionTypes {
  SET_AUTH_CONTEXT = '[Auth] Set Auth Context',
  SET_SHARED_LINK = '[Auth] Set Shared Link',
}

export const setAuthContext = createAction(
  AuthActionTypes.SET_AUTH_CONTEXT,
  props<{authContext: AuthContext}>()
);

export const setSharedLink = createAction(
  AuthActionTypes.SET_SHARED_LINK,
  props<{sharedLink: SharedLink}>()
);
