import { createSelector } from '@ngrx/store';
import { orgsEntityAdapter } from './orgs.state';
import { Org } from './org';

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = orgsEntityAdapter.getSelectors();

const orgEntities = (state: any) =>  state.orgs.orgs.entities;
export const orgs = createSelector(orgEntities, state => Object.values(state) as Array<Org>);
