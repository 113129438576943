import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { RoutingService } from '../routing/routing.service';
import { AuthService, User } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultOrgResolver  {

  constructor(private authService: AuthService,  private routingService: RoutingService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {

    const promise = this.authService.getCurrentUser().then(user => {
      const test = new RegExp(/org\/(.*?)\//).exec(window.location.pathname);
      const org = test?.length ? test[1] : null;
      const paramOrgSlug = route.paramMap.get('org');

      if (org || paramOrgSlug) {
        return org;
      }
      console.log('DefaultOrgResolver: No org in route: ');


      const localStorageOrgSlug = localStorage.getItem('lastOrgSlug');
      const localStorageOrg = user?.orgs.find(o => o.orgSlug === localStorageOrgSlug);
      if (localStorageOrgSlug && localStorageOrg){
        this.authService.setCurrentOrg(localStorageOrg);
        console.log('returning local storage org: ', localStorageOrgSlug);
        setTimeout(() => {
          // this.routingService.switchOrg(localStorageOrgSlug);
        }, 1);
        return '';
      }

      const firstOrg = user?.orgs?.length ? user.orgs[0] : null;
      if (firstOrg) {
        this.authService.setCurrentOrg(firstOrg);
        setTimeout(() => {
          // this.routingService.switchOrg(firstOrg.orgSlug);
        }, 1);
      }
      return '';
    });
    return from(promise);
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
  }
  getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
        .filter(v => v.routeConfig)
        .map(v => v.routeConfig!.path)
        .join('/');
  }
}


