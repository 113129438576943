import { createAction, props } from '@ngrx/store';
import { UserSession } from '../user-session';

export enum UserSessionActionTypes {
  LOAD_REMOTE_USER_SESSIONS = '[UserSession] Load Remote User Sessions',
  LOAD_REMOTE_USER_SESSIONS_SUCCESS = '[UserSession] Load Remote User Sessions Success',
  LOAD_REMOTE_USER_SESSIONS_FAILURE = '[UserSession] Load Remote User Sessions Failure',
  JOIN_USER_SESSION = '[UserSession] Join Session',
  LEAVE_USER_SESSION = '[UserSession] Leave Session',
  ADD_REMOTE_USER_TO_SESSION = '[UserSession] Add Remote User to Session',
  REMOVE_REMOTE_USER_FROM_SESSION = '[UserSession] Remove Remote User from Session',
}

export const joinSession = createAction(
  UserSessionActionTypes.JOIN_USER_SESSION,
  props<{sessionId: string}>()
);

export const leaveSession = createAction(
  UserSessionActionTypes.LEAVE_USER_SESSION,
  (userSession: UserSession) => ({userSession})
);

export const addRemoteUserToSession = createAction(
  UserSessionActionTypes.ADD_REMOTE_USER_TO_SESSION,
  (userSession: UserSession) => ({userSession})
);

export const removeRemoteUserFromSession = createAction(
  UserSessionActionTypes.REMOVE_REMOTE_USER_FROM_SESSION,
  (userSession: UserSession) => ({userSession})
);

export const loadRemoteUsers = createAction(
  UserSessionActionTypes.LOAD_REMOTE_USER_SESSIONS,
  props<{sessionId: string}>()
);

export const loadRemoteUsersSuccess = createAction(
  UserSessionActionTypes.LOAD_REMOTE_USER_SESSIONS_SUCCESS,
  props<{ data: any }>()
);

export const loadRemoteUsersFailure = createAction(
  UserSessionActionTypes.LOAD_REMOTE_USER_SESSIONS_FAILURE,
  props<{ error: any }>()
);


