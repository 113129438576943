export class Service {
  name: string;
  version: string;
  date: string;

  constructor(name: string, version: string, date: string ){
    this.name = name;
    this.version = version;
    this.date = date;
  }
}