import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { LoadingIndicatorComponent } from './loading-indicator.component';

@NgModule({
  declarations: [LoadingIndicatorComponent],
  imports: [
    MatSnackBarModule,
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [LoadingIndicatorComponent]
})

export class LoadingIndicatorModule { }
