import { UserSessionStoreState } from '../common/user-sessions/user-sessions-store';
import { AuthStoreState } from '../common/auth/auth-store';
import { LoadingIndicatorStoreState } from '../common/loading-indicator/loading-indicator-store';
import { ServicesStoreState } from '../service-versions/store';
import { OrgsStoreState } from '../orgs/orgs';

export interface State {
  userSessions: UserSessionStoreState.State;
  auth: AuthStoreState.State;
  orgs: OrgsStoreState.State;
  loadingIndicator: LoadingIndicatorStoreState.State;
  services: ServicesStoreState.State;
}
