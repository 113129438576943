import { createAction, props } from '@ngrx/store';
import { Org } from './org';


export enum OrgsActionTypes {
    LOAD_ORGS = '[Orgs] Load Orgs',
    LOAD_ORGS_SUCCESS = '[Orgs] Load Orgs Success',
    LOAD_ORGS_FAILURE = '[Orgs] Load Orgs Failure',

    CREATE_ORG = '[Orgs] Create Org',
    CREATE_ORG_SUCCESS = '[Orgs] Create Org Success',
    CREATE_ORG_FAILURE = '[Orgs] Create Org Failure',

    UPDATE_ORG = '[Orgs] Update Org',
    UPDATE_ORG_SUCCESS = '[Orgs] Update Org Success',
    UPDATE_ORG_FAILURE = '[Orgs] Update Org Failure',

}

////////////////////////////////////////////////////////////////////////////////
export const loadOrgs = createAction(
  OrgsActionTypes.LOAD_ORGS,
);

export const loadOrgsSuccess = createAction(
  OrgsActionTypes.LOAD_ORGS_SUCCESS,
  props<{data: Array<Org>}>()
);

export const loadOrgsFailure = createAction(
  OrgsActionTypes.LOAD_ORGS_FAILURE,
  props<{error: any}>()
);


export const createOrg = createAction(
  OrgsActionTypes.CREATE_ORG,
  props<{name: string}>()
);

export const createOrgSuccess = createAction(
  OrgsActionTypes.CREATE_ORG_SUCCESS,
  props<{data: Org}>()
);

export const createOrgFailure = createAction(
  OrgsActionTypes.CREATE_ORG_FAILURE,
  props<{error: any}>()
);

export const updateOrg = createAction(
  OrgsActionTypes.UPDATE_ORG,
  props<{id: string, isMonitored?: boolean, isArchived?: boolean}>()
);

export const updateOrgSuccess = createAction(
  OrgsActionTypes.UPDATE_ORG_SUCCESS,
  props<{data: Org}>()
);

export const updateOrgFailure = createAction(
  OrgsActionTypes.UPDATE_ORG_FAILURE,
  props<{error: any}>()
);


