import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  private authContext;
  constructor(private router: Router, private activeRoute: ActivatedRoute, private authService: AuthService) {
    this.authService.authContext.subscribe(authContext => {
      this.authContext = authContext;
    });

  }

  public switchOrg(orgSlug){
    console.log('Routing Service: switchOrg: ', orgSlug);
    this.router.navigate([`/org/${orgSlug}`]);
  }
  public go(path, params = {}){
    const newPath = `/org/${this.authContext?.currentOrg?.orgSlug}/${path}`;
    console.log('go: ', newPath);
    this.router.navigate([newPath], {
      queryParams: params
    });
  }
  public getRouterLink(path){
    return `/org/${this.authContext?.currentOrg?.orgSlug}/${path}`;
  }
  public isRoute(path) {
    return this.router.url.endsWith(path);
  }
}
