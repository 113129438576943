export class ColorUtil {
  public static stringToHslaColor(str, s = 30, l = 60, a = 1) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    // hsla(0, 100%, 50%, 0.2)
    return 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + a + ')';
  }
}
