import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Service } from './service';

type ServiceState = EntityState<Service>

export interface State {
  services: ServiceState;
}
export const serviceEntityAdapter: EntityAdapter<Service> = createEntityAdapter<Service>({
  selectId: (entity => entity.name)
});

export const servicesInitialState = {
  services: serviceEntityAdapter.getInitialState({}),
};
