import { Injectable } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Observable, of as observableOf, from } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { OrgActions } from ".";
import { RootStoreState } from "src/app/root-store";
import { OrgService } from "./orgs.service";

@Injectable()
export class OrgsEffects {
  constructor(
    private actions$: Actions,
    private orgService: OrgService,
    private store: Store<RootStoreState.State>
  ) {}

  loadOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.OrgsActionTypes.LOAD_ORGS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.orgService.getOrgs()).pipe(
          map((data) => OrgActions.loadOrgsSuccess({ data })),
          catchError((error) =>
            observableOf(OrgActions.loadOrgsFailure({ error }))
          )
        );
      })
    )
  );

  createOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.OrgsActionTypes.CREATE_ORG),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.orgService.createOrg(action.name)).pipe(
          map((data) => OrgActions.createOrgSuccess({ data })),
          catchError((error) =>
            observableOf(OrgActions.createOrgFailure({ error }))
          )
        );
      })
    )
  );

  updateOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.OrgsActionTypes.UPDATE_ORG),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.orgService.updateOrg(action)).pipe(
          map((data) => OrgActions.updateOrgSuccess({ data })),
          catchError((error) => {
            console.error("got error:", error);
            return observableOf(OrgActions.updateOrgFailure({ error }));
          })
        );
      })
    )
  );
}
