import { createAction, props } from '@ngrx/store';
import { Service } from './service';

export enum ServicesActionTypes {
    LOAD_SERVICES = '[Services] Load Services',
    LOAD_SERVICES_SUCCESS = '[Services] Load Services Success',
    LOAD_SERVICES_FAILURE = '[Services] Load Services Failure',

    DEPLOY_SERVICE = '[Services] Deploy Service',
    DEPLOY_SERVICE_SUCCESS = '[Services] Deploy Service Success',
    DEPLOY_SERVICE_FAILURE = '[Services] Deploy Service Failure',

    DELETE_SERVICE = '[Services] Delete Service',
    DELETE_SERVICE_SUCCESS = '[Services] Delete Service Success',
    DELETE_SERVICE_FAILURE = '[Services] Delete Service Failure',

    UPDATE_SERVICE = '[Services] Update Service',
    UPDATE_SERVICE_SUCCESS = '[Services] Update Service Success',
    UPDATE_SERVICE_FAILURE = '[Services] Update Service Failure',

    LOAD_CURRENT_SERVICE = '[Services] Load Current Service',
    LOAD_CURRENT_SERVICE_SUCCESS = '[Services] Load Current Service Success',
    LOAD_CURRENT_SERVICE_FAILURE = '[Services] Load Current Service Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadServices = createAction(
  ServicesActionTypes.LOAD_SERVICES,
);

export const loadServicesSuccess = createAction(
  ServicesActionTypes.LOAD_SERVICES_SUCCESS,
  props<{data: Array<any>}>()
);

export const loadServicesFailure = createAction(
  ServicesActionTypes.LOAD_SERVICES_FAILURE,
  props<{error: any}>()
);

export const deployService = createAction(
  ServicesActionTypes.DEPLOY_SERVICE,
  props<{serviceName: string}>()
);

export const deployServiceSuccess = createAction(
  ServicesActionTypes.DEPLOY_SERVICE_SUCCESS,
  props<{service: Service}>()
);

export const deployServiceFailure = createAction(
  ServicesActionTypes.DEPLOY_SERVICE_FAILURE,
  props<{error: any}>()
);

export const updateService = createAction(
  ServicesActionTypes.UPDATE_SERVICE,
  props<{id: string, changes: Service}>()
);

export const updateServiceSuccess = createAction(
  ServicesActionTypes.UPDATE_SERVICE_SUCCESS,
  props<{id: string, changes: Service}>()
);

export const updateServiceFailure = createAction(
  ServicesActionTypes.UPDATE_SERVICE_FAILURE,
  props<{error: any}>()
);

export const deleteService = createAction(
  ServicesActionTypes.DELETE_SERVICE,
  props<{service: Service}>()
);

export const deleteServiceSuccess = createAction(
  ServicesActionTypes.DELETE_SERVICE_SUCCESS,
  props<{service: Service}>()
);

export const deleteServiceFailure = createAction(
  ServicesActionTypes.DELETE_SERVICE_FAILURE,
  props<{error: any}>()
);

export const loadCurrentService = createAction(
  ServicesActionTypes.LOAD_CURRENT_SERVICE,
  props<{id: string}>()
);

export const loadCurrentServiceSuccess = createAction(
  ServicesActionTypes.LOAD_CURRENT_SERVICE_SUCCESS,
  props<{service: Service}>()
);

export const loadCurrentServiceFailure = createAction(
  ServicesActionTypes.LOAD_CURRENT_SERVICE_FAILURE,
  props<{error: any}>()
);
