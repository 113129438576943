import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatSpinnerComponent } from './mat-spinner/mat-spinner.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FilterMenuModalComponent } from './filter-menu/filter-menu-modal/filter-menu-modal.component';
import { FilterEntityComponent } from './filter-menu/filter-entity/filter-entity.component';
import { AvatarComponent } from './avatar/avatar.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MenuComponent } from './menu/menu.component';
import { PageSectionComponent } from './page-section/page-section.component';
import { PageSectionHeaderComponent } from './page-section/page-section-header/page-section-header.component';
import { PageSectionSubTitleComponent } from './page-section/page-section-sub-title/page-section-sub-title.component';
import { PageSectionTitleComponent } from './page-section/page-section-title/page-section-title.component';
import { ListComponent } from './list/list.component';
import { PageSectionActionsComponent } from './page-section/page-section-actions/page-section-actions.component';
import { PageSectionBodyComponent } from './page-section/page-section-body/page-section-body.component';
import { ListItemComponent } from './list/list-item/list-item.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box';
import { UiTestComponent } from './ui-test/ui-test.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { AppSwitchComponent } from './app-switch/app-switch.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { EmptyPlaceholderComponent } from './empty-placeholder/empty-placeholder.component';


@NgModule({
  declarations: [
    SearchBarComponent,
    ModalComponent,
    TabNavigationComponent,
    FilterMenuComponent,
    MatSpinnerComponent,
    FilterMenuModalComponent,
    FilterEntityComponent,
    AvatarComponent,
    PaginationComponent,
    MenuComponent,
    PageSectionComponent,
    PageSectionHeaderComponent,
    PageSectionSubTitleComponent,
    PageSectionTitleComponent,
    PageSectionBodyComponent,
    ListComponent,
    ListItemComponent,
    PageSectionActionsComponent,
    ConfirmationBoxComponent,
    UiTestComponent,
    AppSwitchComponent,
    LeftSidebarComponent,
    EmptyPlaceholderComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatRadioModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSliderModule
  ],
  exports: [
    SearchBarComponent,
    ModalComponent,
    TabNavigationComponent,
    FilterMenuComponent,
    MatSpinnerComponent,
    FilterMenuModalComponent,
    AvatarComponent,
    PaginationComponent,
    MenuComponent,
    PageSectionComponent,
    PageSectionHeaderComponent,
    PageSectionSubTitleComponent,
    PageSectionTitleComponent,
    ListComponent,
    ListItemComponent,
    PageSectionActionsComponent,
    PageSectionBodyComponent,
    UiTestComponent,
    AppSwitchComponent,
    LeftSidebarComponent,
    EmptyPlaceholderComponent]
})
export class ComponentsModule { }
