import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private authService: AuthService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      // fix for localhost development: accept cookies from a URL parameter
      // assumption: authentication cookies are checked by the backend for validity
      // so even if an attacker injects these cookies, they will be rejected
      const urlParams = new URLSearchParams(window.location.search);
      const accessTokenCookieParam = urlParams.get('vibeAccessToken');
      const refreshTokenCookieParam = urlParams.get('vibeRefreshToken');
      if (accessTokenCookieParam && refreshTokenCookieParam) {
        this.authService.setAccessToken(accessTokenCookieParam);
        this.authService.setRefreshToken(refreshTokenCookieParam);
      }

      const isSignedIn: boolean = this.authService.isSignedIn();
      console.debug("is signed in?", isSignedIn)
      if (!isSignedIn) {
        console.debug("guard: attempting access token refresh");
        if(await this.authService.refreshAccessToken()) {
          return true;
        }
        console.debug("failed to reauthenticate");
        await this.authService.clearCognitoData();
        const loginRedirect = this.authService.getLoginUrl() + "/login?returnUrl=" + environment.opsAppHost + state.url
        console.debug("redirecting to login with return Url of", loginRedirect)
        document.location.href = loginRedirect;
      }
      return isSignedIn;
    } catch (error) {
      console.error('error in auth guard.. routing to login: ', error);
      console.debug(`should re-route to ${JSON.stringify(state.url)}`);
      const loginRedirect = this.authService.getLoginUrl() + "/login?returnUrl=" + environment.opsAppHost + state.url
      console.debug("redirecting to login with return Url of", loginRedirect)
      document.location.href = loginRedirect;
      return false;
    }
  }

}
