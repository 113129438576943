import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@common/auth/auth.guard';
import { DefaultOrgResolver } from '@common/auth/default-org.resolver';
import { MainComponent } from './main/main.component';
import { UserOrgResolver } from '@common/auth/user-org.resolver';
import { SelectOrgComponent } from './main/select-org/select-org.component';
import { PlatformOpsAuthGuard } from '@common/auth/platform_ops_auth.gaurd';
import { PlatformManagerAuthGuard } from '@common/auth/platform_manager_auth.gaurd';


const routes: Routes = [
  {
    path: '', component: MainComponent, canActivate: [AuthGuard], resolve: { defaultOrg: DefaultOrgResolver, },
    children: [
      { path: '', loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule) },
      { path: 'feature-branches', canActivate: [PlatformOpsAuthGuard], loadChildren: () => import('./feature-branches/feature-branches.module').then(m => m.FeatureBranchesModule) },
      { path: 'services', canActivate: [PlatformOpsAuthGuard],loadChildren: () => import('./service-versions/service-versions.module').then(m => m.ServiceVersionsModule) },
      { path: 'orgs', canActivate: [PlatformManagerAuthGuard], loadChildren: () => import('./orgs/orgs.module').then(m => m.OrgsModule) },

      { path: 'feature-flags', component: SelectOrgComponent  },
      {
        path: 'org/:org', resolve: { user: UserOrgResolver }, children: [
          { path: 'feature-flags', canActivate: [PlatformManagerAuthGuard], loadChildren: () => import('./feature-flags/feature-flags.module').then(m => m.FeatureFlagsModule) },
        ]
      },
      { path: 'document-access', canActivate: [PlatformManagerAuthGuard], loadChildren: () => import('./document-access/document-access.module').then(m => m.DocumentAccessModule)  },
      { path: 'user-activity', canActivate: [PlatformManagerAuthGuard], loadChildren: () => import('./user-activity/user-activity.module').then(m => m.UserModule)  },

      { path: 'platform-users', canActivate: [PlatformManagerAuthGuard], loadChildren: () => import('./platform-users/platform-users.module').then(m => m.PlatformUserModule)  },
      { path: 'entity-viewer', canActivate: [PlatformManagerAuthGuard], loadChildren: () => import('./entity-viewer/entity-viewer.module').then(m => m.EntityViewerModule)  },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
