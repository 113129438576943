import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { servicesReducers } from './service-versions.reducers';
import { ServicesEffects } from './service-versions.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('services', servicesReducers),
    EffectsModule.forFeature([ServicesEffects])
  ]
})
export class ServiceVersionsStoreModule { }
