import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-no-orgs-registered',
  templateUrl: './no-orgs-registered.component.html',
  styleUrls: ['./no-orgs-registered.component.scss']
})
export class NoOrgsRegisteredComponent implements OnInit {

  public user;
  constructor(private authService: AuthService) {

  }
  async ngOnInit() {
    this.user = await this.authService.getCurrentUser();
  }

  logout(){
    this.authService.logout();
  }

}
